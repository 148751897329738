import { combineReducers } from '@reduxjs/toolkit';
import trackerReducer from './Tracker/trackerSlice';
import notificationReducer from './Notification/userNotificationsSlice';
import overTimeReducer from './OverTimeList/overTimeSlice';
import profileReducer from './Profile/profileSlice';

const rootReducer = combineReducers({
  timeTracker: trackerReducer,
  notifications: notificationReducer,
  overTime: overTimeReducer,
  profile: profileReducer
});

export default rootReducer;
